// @flow
import React from "react";
import {
  BlankRow,
  CenterColumn,
  ColumnHeader,
  ColumnWrapper,
  DrinkInfo,
  LeftColumn,
  MenuItem,
  MenuWrapper,
  RightColumn,
  Title
} from "./Menu.css";

const coffeeMenu = [
  {
    name: "espresso",
    size: "4",
    price: 2.5
  },
  {
    name: "americano",
    size: "6",
    price: 3.5
  },
  {
    name: "macchiato",
    size: "6",
    price: 3.5
  },
  {
    name: "cortado",
    size: "12",
    price: 3.5
  },
  {
    name: "cappuccino",
    size: "12",
    price: 3.5
  },
  {
    name: "flat white",
    size: "12",
    price: 3.5
  },
  {
    name: "latte",
    size: "12",
    price: 3.5
  },
  {
    name: "brewed coffee",
    size: "6",
    price: 3.5
  },
  {
    name: "cold brew",
    size: "10",
    price: 3.5
  }
];

const teaLatte = [
  {
    name: "tea latte",
    size: "12",
    price: 2.5
  },
  {
    name: "matcha latte",
    size: "12",
    price: 3.5
  },
  {
    name: "chai latte",
    size: "12",
    price: 3.5
  },
  {
    name: "turmeric latte",
    size: "12",
    price: 3.5
  }
];

const teaMenu = [
  {
    name: "english breakfast",
    size: "4",
    price: 2.5
  },
  {
    name: "earl grey supreme",
    size: "6",
    price: 3.5
  },
  {
    name: "dragon pearl jasmine",
    size: "6",
    price: 3.5
  },
  {
    name: "japanese sencha",
    size: "12",
    price: 3.5
  },
  {
    name: "chamomile herbal",
    size: "12",
    price: 3.5
  },
  {
    name: "peppermint herbal",
    size: "6",
    price: 3.5
  },
  {
    name: "citrus green",
    size: "6",
    price: 3.5
  }
];

const signatureMenu = [
  {
    name: "brown sugar latte",
    size: "4",
    price: 5.5
  },
  {
    name: "pumpkin spice latte (PSL)",
    size: "5",
    price: 3.5
  },
  {
    name: "rose matcha latte",
    size: "4",
    price: 5.5
  },
  {
    name: "lavender matcha and oatmilk (LMAO) latte",
    size: "6",
    price: 3.5
  },
];

const Menu = () => (
  <MenuWrapper>
    <Title>Menu</Title>
    <ColumnWrapper>
      <CenterColumn>
        <ColumnHeader>Signature</ColumnHeader>
        <MenuItem>
          <tbody>
            {signatureMenu.map(item => (
              <tr key={item.name}>
                <DrinkInfo>{item.name}</DrinkInfo>
              </tr>
            ))}
          </tbody>
        </MenuItem>
      </CenterColumn>
    </ColumnWrapper>

    <ColumnWrapper>
      <LeftColumn>
        <ColumnHeader>Espresso Drinks</ColumnHeader>
        <MenuItem>
          <tbody>
            {coffeeMenu.map(item => (
              <tr key={item.name}>
                <DrinkInfo>{item.name}</DrinkInfo>
              </tr>
            ))}
            <BlankRow height="15px">
              <td />
            </BlankRow>
            {/* <tr>
              <DrinkInfo>espresso shot</DrinkInfo>
            </tr>
            <tr>
              <DrinkInfo>alt milk</DrinkInfo>
            </tr>
            <BlankRow height="10px">
              <td />
            </BlankRow> */}
          </tbody>
        </MenuItem>
      </LeftColumn>
      <RightColumn>
        <ColumnHeader>Tea</ColumnHeader>
        <MenuItem>
          <tbody>
            {teaLatte.map(item => (
              <tr key={item.name}>
                <DrinkInfo>{item.name}</DrinkInfo>
              </tr>
            ))}
            <BlankRow height="15px">
              <td />
            </BlankRow>
            {teaMenu.map(item => (
              <tr key={item.name}>
                <DrinkInfo>{item.name}</DrinkInfo>
              </tr>
            ))}
          </tbody>
        </MenuItem>
      </RightColumn>
    </ColumnWrapper>
  </MenuWrapper>
);

export default Menu;
