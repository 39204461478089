// @flow
import React, { Fragment } from "react";
import Footer from "./components/footer";
import Menu from "./components/menu";
import OurStory from "./components/ourStory";
import Hours from "./components/hours";
import Nav from "./components/nav/desktopNav";
import MobileNav from "./components/nav/mobileNav";
import { HomePageHero } from "./App.css";
import { GlobalStyle } from "./styles/globalStyles";
import NewsletterSignup from "./components/newsletterSignup";

const App = ({ isDesktop, isSignupOpen, closeSignup }) => (
  <Fragment>
    <GlobalStyle />
    {isDesktop ? <Nav /> : <MobileNav />}
    <NewsletterSignup
      isSignupOpen={isSignupOpen}
      closeSignup={closeSignup}
      isDesktop={isDesktop}
    />
    <HomePageHero isDesktop={isDesktop} isSignupOpen={isSignupOpen} />
    <OurStory />
    <Hours />
    <Menu />
    <Footer />
  </Fragment>
);

export default App;
