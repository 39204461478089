/* eslint no-unused-expressions: 0 */
import { css } from 'styled-components';

import FuturaCondensedBdTTF from '../assets/fonts/Futura/Futura_Condensed_Bd.ttf';
import FuturaCondensedBdWOFF from '../assets/fonts/Futura/Futura_Condensed_Bd.woff';
import FuturaCondensedBdWOFF2 from '../assets/fonts/Futura/Futura_Condensed_Bd.woff2';

export const globalFonts = css`
  @font-face {
    font-family: 'futura';
    font-display: swap;
    src: local(${FuturaCondensedBdTTF}) format('truetype'),
    local(${FuturaCondensedBdWOFF}) format('woff'),
    local(${FuturaCondensedBdWOFF2}) format('woff2');
    font-weight: 700;
  }
`