import styled from "styled-components";

export const HomePageHero = styled.div`
  ${props =>
    props.isSignupOpen
      ? 'background-attachment: inherit;'
      : 'background-attachment: fixed;'}
  width: 100vw;
  height: ${props =>
      props.isDesktop ? "calc(100vh - 180px);" : "calc(100vh - 450px);"}
    @media (hover: none) {
    background-attachment: inherit;
  }
  margin: 0px 0 10px 0;
  background-position: center;
  background-size: cover;
  background-image: url("https://res.cloudinary.com/dhbslryus/image/upload/f_auto,q_auto:low,c_fill,g_auto,dpr_auto,w_auto/v1561313942/lazy-suzy-home-hero.jpg");
`;
