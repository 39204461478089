import styled from "styled-components";
import * as Typography from "../../../styles/typography";

export const MobileNavWrapper = styled.header`
  top: 0px;
  position: sticky;
  z-index: 2;
  background-color: white;
  display: flex;
  flex-direction: row;
`

export const HeaderTextElement = styled.h1`
  text-indent: -9999px;
`

export const Logo = styled.div`
  height: 58px;
  width: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px, 60px;
  background-image: url("https://res.cloudinary.com/dhbslryus/image/upload/f_auto,q_auto,c_fill,dpr_auto,w_auto/v1554766820/lazy_suzy_type_logo");
  position: absolute;
  left: 50%;
  transform: translateX(-50%)
`;

export const MobileNavMenu = styled.nav.attrs({
  'aria-label': 'Mobile Accessibility Navigation Dropdown',
  'role': 'navigation'
})`
  ${Typography.FUTURA_14};
  display: ${props => props.isMenuOpen ? 'flex' : 'none'};
  height: 250px;
  width: 150px;
  align-items: center;
  position: fixed;
  background-color: white;
  z-index: 2;
`

export const MobileNavItem = styled.button.attrs({
  'type': 'button'
})`
  ${Typography.FUTURA_12};
  background-color: white;
  cursor: pointer;
  border: none;

  :active,
  :focus {
    outline: none;
    font-weight: 600;
  }
`

export const MobileNavList = styled.ul`
  list-style-type: none;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  padding-left: 0;
  flex-direction: column;
  justify-content: space-evenly;
`

export const Title = styled.h2``;
