import styled from "styled-components";
import * as Color from "../../styles/color";
import * as Typography from "../../styles/typography";

export const FooterWrapper = styled.div.attrs({
  id: 'contact'
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 320px;
  padding: 50px 10px 60px 10px;
  line-height: 30px;
`;

export const Title = styled.h2``;

export const InfoField = styled.p`
  ${Typography.FUTURA_14};
  opacity: 0.6;
  margin: 0;

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    transition: background-color 0.15s ease-in;
    border-bottom: 3px solid ${Color.BLACK};
    &:hover {
      color: ${Color.WHITE};
      background-color: ${Color.ROSE};
    }
  }
`;

export const SocialLinkWrapper = styled.div`
  background-color: ${Color.WHITE};
  width: auto;
  display: inline-block;
  z-index: 3;
  color: white;
  position: relative;
  margin: 0;
`;

export const TwitterLink = styled.a`
  margin: 0 20px;
  color: #38A1F3;
  :active,
  :focus {
    color: #026777;
  }
  :hover {
    color: ${Color.ROSE};
  }
`;

export const YelpLink = styled(TwitterLink)`
  color: red;
  :active,
  :focus {
    color: red;
  }
  :hover {
    color: ${Color.ROSE};
  }
`;

export const InstagramLink = styled(TwitterLink)`
  color: ${Color.BLACK};
  :active,
  :focus {
    color: ${Color.BLACK};
  }
  :hover {
    color: ${Color.ROSE};
  }
`;

export const SocialMediaSection = styled.div`
  width: 80%;
  margin-top: 20px;
  text-align: center;
  font-size: 28px;
  position: relative;
  :after {
    content: "";
    width: 100%;
    border-bottom: solid 1px ${Color.GRAY};
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
`;

export const CopyRight = styled.h3`
  ${Typography.FUTURA_12};
  opacity: 0.6;
`