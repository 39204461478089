export const ASH = '#DCD1CF';
export const BLACK = '#000';
export const BLUSH = '#E5C2B5';
// Universal Background Color
export const CLOUD = '#F7F5F4';
export const ROSE = '#CF948D';
export const SAND = '#FEE8D6';
export const SKY = '#BCD9DF';
export const WATER = '#026777';
export const WHITE = '#fff';
export const LEMON = '#FFC41A';
// need gray from design
export const GRAY = '#D1D1D1';
export const LANDING_GRAY = '#EFEDEC';
export const TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.5)';
export const TRANSPARENT_BLACK_2 = 'rgba(0, 0, 0, 0.4)';
export const NEAR_OPAQUE_BLACK = 'rgba(0, 0, 0, 0.9)';
export const NEAR_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.1)';
export const TRANSPARENT_WHITE = 'rgba(255, 255, 255, 0.8)';
export const ERROR_BG_RED = 'rgba(207, 148, 141, 0.8)';
export const DISABLED = '#999999';

export const ISI_BACKGROUND = '#E7f1f3';
