// @flow
import { compose } from 'recompose';
import MobileNav from './MobileNav';
import { withMenu } from "../../../hoc/withMenu";
import { withScrollHandlers } from '../../../hoc/withScrollHandlers';

const enhance = compose(
  withMenu,
  withScrollHandlers,
)

export default enhance(MobileNav);
