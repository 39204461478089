// @flow
import debounce from "lodash/debounce";
import { compose, lifecycle } from "recompose";
import App from "./App";
import { withWidth } from '../src/hoc/withWidth';
import { withNewsletter } from '../src/hoc/withNewsletter';

const withLifecycle = lifecycle({
  componentDidMount() {
    const updateWidth = () => {
      if (window.innerWidth !== this.props.width) {
        this.props.calculateWidth();
      }
    };
    window.addEventListener("resize", debounce(updateWidth, 50));
    this.props.calculateWidth();
  }
});

const enhance = compose(
  withWidth,
  withNewsletter,
  withLifecycle,
)

export default enhance(App);
