import { withHandlers } from 'recompose';

export const withScrollHandlers = withHandlers({
  handleOurStoryScroll: () => () => {
    window.scroll({
      top: document.getElementById("story").offsetTop - 60,
      left: 0,
      behavior: "smooth"
    });
  },
  handleHoursScroll: () => () => {
    window.scroll({
      top: document.getElementById("operatinghours").offsetTop - 60,
      left: 0,
      behavior: "smooth"
    });
  },
  handleMenuScroll: () =>  () => {
    window.scroll({
      top: document.getElementById("menu").offsetTop - 60,
      left: 0,
      behavior: "smooth"
    });
  },
  handleContactScroll: () => () => {
    window.scroll({
      top: document.getElementById("contact").offsetTop - 60,
      left: 0,
      behavior: "smooth"
    });
  },
})