import styled from "styled-components";
import * as Color from "../../styles/color";
import * as Typography from "../../styles/typography";

export const OurStoryWrapper = styled.div.attrs({
  id: 'story'
})`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 600px;
  margin: auto;
  padding: 50px 0 60px 0;
  border-bottom: 1px solid ${Color.GRAY};
`;

export const Title = styled.h2``;

export const StoryBody = styled.div`
  ${Typography.FUTURA_16};
  opacity: 0.6;
  letter-spacing: 1px;
  line-height: 30px;
  width: calc(100% - 40px);
`;
