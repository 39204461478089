// @flow
import React from "react";
import { OperatingHours, OperatingHoursWrapper, Title } from "./Hours.css";

// const hoursArray = [
//   ["Sunday", "8 am", "7 pm"],
//   ["Monday", "7 am", "7 pm"],
//   ["Tuesday", "7 am", "7 pm"],
//   ["Wednesday", "7 am", "7 pm"],
//   ["Thursday", "7 am", "7 pm"],
//   ["Friday", "7 am", "7 pm"],
//   ["Saturday", "8 am", "7 pm"]
// ];

const hoursArray = [
  { dayOfWeek: "Sunday", openingHour: ['9 am', '09:00'], closingHour: ['3 pm', '15:00']},
  { dayOfWeek: "Monday", openingHour: ['8 am', '08:00'], closingHour: ['5 pm', '17:00']},
  { dayOfWeek: "Tuesday", openingHour: ['8 am', '08:00'], closingHour: ['5 pm', '17:00']},
  { dayOfWeek: "Wednesday", openingHour: ['8 am', '08:00'], closingHour: ['5 pm', '17:00']},
  { dayOfWeek: "Thursday", openingHour: ['8 am', '08:00'], closingHour: ['5 pm', '17:00']},
  { dayOfWeek: "Friday", openingHour: ['8 am', '08:00'], closingHour: ['5 pm', '17:00']},
  { dayOfWeek: "Saturday", openingHour: ['9 am', '09:00'], closingHour: ['3 pm', '15:00']},
];

const Hours = () => (
  <OperatingHoursWrapper>
    <Title>Hours</Title>
    <OperatingHours>
      <tbody>
        {hoursArray.map(({ dayOfWeek, openingHour, closingHour }) => (
          <tr key={dayOfWeek}>
            <td>{dayOfWeek}</td>
            <td>
              <time dateTime={openingHour[1]}>{openingHour[0]}</time> / <time dateTime={closingHour[1]}>{closingHour[0]}</time>
              </td>
          </tr>
        ))}
      </tbody>
    </OperatingHours>
  </OperatingHoursWrapper>
);

export default Hours;
