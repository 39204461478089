import styled from "styled-components";
import * as Color from "../../styles/color";
import * as Typography from "../../styles/typography";

export const OperatingHoursWrapper = styled.div.attrs({
  id: 'operatinghours'
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 600px;
  margin: auto;
  padding: 50px 0 60px 0;
  border-bottom: 1px solid ${Color.GRAY};
`;

export const Title = styled.h2``;

export const OperatingHours = styled.table`
  ${Typography.FUTURA_16};
  opacity: 0.6;
  line-height: 25px;
`;
