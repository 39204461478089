import styled, { css } from "styled-components";
import * as Color from "../../styles/color";
import * as Typography from "../../styles/typography";
import close from "../../assets/images/close-x.svg";

export const NewsletterSignupWrapper = styled.div.attrs({
  id: "newsletter-signup"
})`
  display: ${props => (props.isSignupOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 320px;
  padding: 0px 10px 60px 10px;
  line-height: 30px;
  margin: auto;
  max-width: 700px;
  position: relative;
`;

export const Form = styled.form.attrs({
  method: "post",
  "form-name": "Users",
  name: "Users"
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0 10px 0;
  input {
    ${Typography.FUTURA_18};
    border: 1px solid ${Color.GRAY};
    height: 50px;
    width: 300px;
    padding: 10px;
    outline: none;

    &:focus, &:active {
      border: 1px solid ${Color.LEMON};
    }
  }
`;

export const Title = styled.h4`
  margin: 0;
`;

export const InfoField = styled.p`
  ${Typography.FUTURA_14};
  opacity: 0.6;
  margin: 0;
`;

export const BtnReset = css`
  outline: none;
  border: none;
  background: none;
`;

export const ButtonBase = css`
  ${BtnReset};
  &[disabled] {
    background-color: ${Color.DISABLED};
    color: ${Color.WHITE};
  }
`;

export const SignupButton = styled.button.attrs({
  "aria-label": "Newsletter Signup",
  name: "signup"
})`
  ${ButtonBase};
  ${Typography.FUTURA_12};

  position: relative;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 400;
  line-height: 16px;
  margin: ${props => props.margin || "0 auto"};
  padding: ${props => props.padding || "0 20px 0 20px"};
  height: 50px;
  color: ${props => props.color || Color.BLACK};
  background-color: ${props => props.backgroundColor || Color.WHITE};
  text-align: center;
  cursor: pointer;
  width: ${props => props.width || "100%"};
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  ${props =>
    props.borderColor &&
    css`
      border-style: solid;
      border-width: 1px;
      border-color: ${props => props.borderColor || "transparent"};
    `};
`;

export const InvisibleButton = styled.button`
  ${BtnReset};
  cursor: pointer;
`;

export const CloseButton = styled(InvisibleButton).attrs({
  "aria-label": "Close",
  name: "close"
})`
  width: 30px;
  height: 30px;
  position: absolute;
  background: url(${close}) top left no-repeat;
  padding: 0;
  margin: 0;
  right: 50px;
`;
