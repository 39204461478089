import { css } from 'styled-components';

// Font family
export const FUTURA = css`
  font-family: futura, sans-serif;
`;

// paragraph styles
export const HEADING_1 = css`
  ${FUTURA};
  font-size: 28px;
  color: #414e58;
  letter-spacing: 0;
  line-height: 38px;
`;

export const HEADING_2 = css`
  font-family: LFT-Etica-SemiBold;
  font-size: 18px;
  color: #414e58;
  letter-spacing: 0;
  line-height: 26px;
`;

export const LARGE_PARAGRAPH = css`
  ${FUTURA};
`;

export const SMALL_PARAGRAPH = css`
  ${FUTURA};
  font-size: 12px;
  color: #414e58;
  letter-spacing: 0;
  line-height: 20px;
`;

export const FUTURA_8 = css`
  ${FUTURA};
  font-size: 8px;
`;

export const FUTURA_10 = css`
  ${FUTURA};
  font-size: 10px;
`;

export const FUTURA_12 = css`
  ${FUTURA};
  font-size: 12px;
`;

export const FUTURA_14 = css`
  ${FUTURA};
  font-size: 14px;
`;

export const FUTURA_16 = css`
  ${FUTURA};
  font-size: 16px;
`;

export const FUTURA_18 = css`
  ${FUTURA};
  font-size: 18px;
`;

export const FUTURA_20 = css`
  ${FUTURA};
  font-size: 20px;
`;

export const FUTURA_24 = css`
  ${FUTURA};
  font-size: 24px;
`;

export const FUTURA_28 = css`
  ${FUTURA};
  font-size: 28px;
`;

export const FUTURA_40 = css`
  ${FUTURA};
  font-size: 40px;
`;

export const FUTURA_50 = css`
  ${FUTURA};
  font-size: 50px;
`;
