// @flow
import { withStateHandlers } from "recompose";
import axios from "axios";
import NewsletterSignup from "./NewsletterSignup";
import { toast } from "../../utils/toast";
import { encode } from "../../utils/encode";

const withSignupForm = withStateHandlers(
  ({ initialState = "" }) => ({
    email: initialState
  }),
  {
    handleChange: ({ email }) => event => ({
      email: event.target.value
    }),
    handleSubmit: ({ email }, { closeSignup, isDesktop }) => event => {
      event.preventDefault();
      axios
        .post(
          "/",
          encode({
            "form-name": "Users",
            email
          })
        )
        .then(response => {
          toast(isDesktop).fire({
            type: "success",
            title: "Thank You For Signing Up!"
          });
          closeSignup();
        })
        .catch(e => {
          toast(isDesktop).fire({
            type: "error",
            title: "Something went wrong, please try again!"
          });
          closeSignup();
        });
    }
  }
);

export default withSignupForm(NewsletterSignup);
