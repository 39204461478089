import { withStateHandlers } from 'recompose';

export const withWidth = withStateHandlers(
  ({ initialState = window.innerWidth }) => ({
    width: initialState,
    isDesktop: true
  }),
  {
    calculateWidth: () => () => ({
      width: window.innerWidth,
      isDesktop: window.innerWidth >= 768
    })
  }
);