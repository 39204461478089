import styled from "styled-components";
import * as Color from "../../styles/color";
import * as Typography from "../../styles/typography";

export const MenuWrapper = styled.div.attrs({
  id: 'menu'
})`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 600px;
  margin: auto;
  padding: 50px 0 60px 0;
  border-bottom: 1px solid ${Color.GRAY};
`;

export const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const CenterColumn = styled.div`
  margin: 0px auto 20px;
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const Title = styled.h2``;

export const MenuItem = styled.table`
  ${Typography.FUTURA_16};
  line-height: 25px;
`

export const DrinkInfo = styled.td`
  padding: 5px 0 0 0;
  opacity: 0.6;
`

export const BlankRow = styled.tr`
  height: ${props => props.height || '30px'};
`

export const ColumnHeader = styled.h3`
  font-size: 14px;
  letter-spacing: 2px;
`
