import { withStateHandlers } from 'recompose';

export const withMenu = withStateHandlers(
  ({ initialState = false }) => ({
    isMenuOpen: initialState
  }),
  {
    setMenu: ({ isMenuOpen }) => () => ({
      isMenuOpen: !isMenuOpen
    })
  }
);
