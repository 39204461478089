/* eslint no-unused-expressions: 0 */
import { createGlobalStyle, css } from 'styled-components';
import vhCheck from 'vh-check';
import { FUTURA } from './typography';
import { globalFonts } from './globalFonts';
import * as Color from './color';

vhCheck('browser-address-bar');

export const HEADER_HEIGHT = 50;
export const FOOTER_HEIGHT = 212;
export const BREAK_POINT = 415;

export const BASE_PADDING = 20;
export const INNER_PADDING = 140;

const MIN_WIDTH = css`
  min-width: 320px;
`;

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  :root {
    --browser-address-bar: 0px;
  }

  header {
    ${MIN_WIDTH};
  }

  body {
    ${MIN_WIDTH};
    ${FUTURA};
    margin: 0;
    padding: 0;
    background-color: ${Color.WHITE};
    -webkit-overflow-scrolling: touch;
    min-height: 100vh;
  }

  a, a:hover, a:fzocus {
    color: ${Color.WATER};
  }

  ${globalFonts}
`;
