import styled from "styled-components";
import * as Color from "../../../styles/color";
import * as Typography from "../../../styles/typography";

export const NavMenuWrapper = styled.div`
  position: relative;
  max-width: 700px;
  margin: auto;
  width: 100%;
  height: 100%;
`
export const HeaderTextElement = styled.h1`
  text-indent: -9999px;
`

export const NavWrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100vw;
  height: 60px;
  margin: auto;
  top: 0px;
  background: white;
  z-index: 2;
  position: sticky;
`;

export const NavMenu = styled.nav.attrs({
  'role': 'Navigation',
  'aria-label': 'Main Navigation'
})`
`;

export const NavList = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 10px;
  margin: 0;
`

export const IconLogo = styled.div`
  position: absolute;
  top: 2px;
  left: 0px;
  height: 50px;
  width: 50px;
  transform: translateX(
    ${props => (props.logoShown === "hidden" ? "-20px" : "0px")}
  );
  transition: ease-in-out 0.5s;
  opacity: ${props => (props.logoShown === "hidden" ? "0" : "1")};
  display: inline-block;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url("https://res.cloudinary.com/dhbslryus/image/upload/f_auto,q_auto,c_fill/h_40,w_auto,dpr_auto,c_scale/v1561320735/lazy-suzy-icon-logo");
`;

export const NavItem = styled.button`
  ${Typography.FUTURA_12};
  padding: 10px 0 10px 0;
  margin-right: 50px;
  letter-spacing: 2px;
  cursor: pointer;
  border: none;
  background-color: ${Color.WHITE};

  :active,
  :focus {
    outline: none;
    font-weight: 600;
  }
`;

export const TypeLogo = styled.div`
  height: 120px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 250px, 120px;
  background-image: url("https://res.cloudinary.com/dhbslryus/image/upload/f_auto,q_auto,c_fill,dpr_auto,w_auto/v1554766820/lazy_suzy_type_logo");
`;

export const Title = styled.h2``;
