import React, { Fragment } from 'react';
import {
  HeaderTextElement,
  IconLogo,
  NavItem,
  NavList,
  NavMenu,
  NavMenuWrapper,
  NavWrapper,
  TypeLogo,
} from './Nav.css';

const Nav = ({
  logoShown,
  width,
  handleOurStoryScroll,
  handleMenuScroll,
  handleHoursScroll,
  handleContactScroll,
}) => (
  <Fragment>
    <TypeLogo>
      <HeaderTextElement>Lazy Suzy Cafe &amps; Shop</HeaderTextElement>
    </TypeLogo>
    <NavWrapper>
      <NavMenuWrapper>
        <IconLogo
          onClick={() =>
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          }
          logoShown={logoShown}
        />
        <NavMenu>
          <NavList>
            <li>
              <NavItem
                onClick={() => {
                  window.open(
                    'https://order.online/business/lazy-suzy-cafe--shop-929897'
                  );
                }}
              >
                ORDER ONLINE
              </NavItem>
            </li>
            <li>
              <NavItem onClick={handleOurStoryScroll}>OUR STORY</NavItem>
            </li>
            <li>
              <NavItem onClick={handleHoursScroll}>HOURS</NavItem>
            </li>
            <li>
              <NavItem onClick={handleMenuScroll}>MENU</NavItem>
            </li>
            <li>
              <NavItem onClick={handleContactScroll}>CONTACT</NavItem>
            </li>
          </NavList>
        </NavMenu>
      </NavMenuWrapper>
    </NavWrapper>
  </Fragment>
);

export default Nav;
