// @flow
import React from "react";
import { ReactComponent as NewsletterIcon } from "../../assets/images/message.svg";
import {
  NewsletterSignupWrapper,
  Title,
  InfoField,
  Form,
  SignupButton,
  CloseButton
} from "./NewsletterSignup.css";
import * as Color from "../../styles/color";

const TITLE = "Get updates from Lazy Suzy";
const SUBTITLE = "Sign up for special offers and events";

const NewsletterSignup = ({ handleChange, handleSubmit, isSignupOpen, closeSignup }) => (
  <NewsletterSignupWrapper isSignupOpen={isSignupOpen}>
    <NewsletterIcon alt="newsletter-icon" />
    <CloseButton onClick={closeSignup}/>
    <Title>{TITLE}</Title>
    <InfoField>{SUBTITLE}</InfoField>
    <Form onSubmit={handleSubmit}>
      <input
        onChange={handleChange}
        type="email"
        name="email"
        placeholder="Email Address"
        aria-label="email"
        required
      />
      <SignupButton
        width="300px"
        margin="10px 0 10px 0"
        backgroundColor={Color.LEMON}
      >
        SIGN UP
      </SignupButton>
    </Form>
  </NewsletterSignupWrapper>
);

export default NewsletterSignup;
