// @flow
import React from "react";
import {
  FooterWrapper,
  Title,
  InfoField,
  SocialLinkWrapper,
  TwitterLink,
  InstagramLink,
  YelpLink,
  SocialMediaSection,
  CopyRight
} from "./Footer.css";

const TITLE = "Please contact us with any inquiries.";

const Footer = () => (
  <FooterWrapper>
    <Title>{TITLE}</Title>
    <InfoField>703 Hart Street</InfoField>
    <InfoField>Brooklyn, NY 11221</InfoField>
    <InfoField>
      For collaborations and general inquiries, please contact:{" "}
      <a href="mailto:hello@lazysuzycafe.com">hello@lazysuzycafe.com</a>
    </InfoField>
    <SocialMediaSection>
      <SocialLinkWrapper>
        <YelpLink
          href={"https://www.yelp.com/biz/lazy-suzy-cafe-and-shop-brooklyn-4/"}
          rel="noopener"
          target="_blank"
          title="Yelp - Lazy Suzy Cafe &amps; Shop"
        >
          <i className="fab fa-yelp" />
        </YelpLink>
        <InstagramLink
          href={"https://www.instagram.com/Lazysuzycafe/"}
          rel="noopener"
          target="_blank"
          title="Instagram - Lazy Suzy Cafe &amps; Shop"
        >
          <i className="fab fa-instagram" />
        </InstagramLink>
        <TwitterLink
          href={"https://www.twitter.com/Lazysuzycafe/"}
          rel="noopener"
          target="_blank"
          title="Twitter - Lazy Suzy Cafe &amps; Shop"
        >
          <i className="fab fa-twitter" />
        </TwitterLink>
      </SocialLinkWrapper>
    </SocialMediaSection>
    <CopyRight>
      Copyright © 2020 — designed by Lazy Suzy Cafe &amp; Shop
    </CopyRight>
  </FooterWrapper>
);

export default Footer;
