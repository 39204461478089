// @flow
import React from "react";
import { OurStoryWrapper, Title, StoryBody } from "./OurStory.css";

const PARAGRAPH_1 =
  "The turntable, “Lazy Susan”, was always a staple at family gatherings. Whether it was to grab a bite from a dish outside arm’s reach, or to pass the last piece of dessert to grandma, the Lazy Susan was always there when we wanted to share. For us, it serves as an instrument that transcends physical boundaries by symbolizing bond, affection, and empathy.";

const PARAGRAPH_2 =
  "Lazy Suzy Cafe & Shop is built off those exact same sentiments. We aim to cultivate the diverse cultures that currently coexist in our community by using our Café as the medium to share and connect with one another. Welcome.";

const OurStory = () => (
  <OurStoryWrapper>
    <Title>Our Story</Title>
    <StoryBody>
      <p>{PARAGRAPH_1}</p>
      <p>{PARAGRAPH_2}</p>
    </StoryBody>
  </OurStoryWrapper>
);

export default OurStory;
