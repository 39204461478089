// @flow
import React, { Fragment } from 'react';
import './MobileNav.css';
import {
  HeaderTextElement,
  Logo,
  MobileNavItem,
  MobileNavList,
  MobileNavMenu,
  MobileNavWrapper,
} from './MobileNav.css.js';

const MobileNav = ({
  isMenuOpen,
  setMenu,
  handleOurStoryScroll,
  handleMenuScroll,
  handleHoursScroll,
  handleContactScroll,
}) => (
  <Fragment>
    <MobileNavWrapper>
      <button
        className={`hamburger hamburger--slider ${
          isMenuOpen ? 'is-active' : ''
        }`}
        type="button"
        onClick={setMenu}
        aria-label="Mobile Navigation Button"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <Logo
        onClick={() =>
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }
      >
        <HeaderTextElement>Lazy Suzy Cafe &amp; Shop</HeaderTextElement>
      </Logo>
    </MobileNavWrapper>
    <MobileNavMenu isMenuOpen={isMenuOpen}>
      <MobileNavList>
        <li>
          <MobileNavItem
            onClick={() => {
              setMenu();
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }}
          >
            HOME
          </MobileNavItem>
        </li>
        <li>
          <MobileNavItem
            onClick={() => {
              setMenu();
              window.open(
                'https://order.online/business/lazy-suzy-cafe--shop-929897'
              );
            }}
          >
            ORDER ONLINE
          </MobileNavItem>
        </li>
        <li>
          <MobileNavItem
            onClick={() => {
              setMenu();
              handleOurStoryScroll();
            }}
          >
            OUR STORY
          </MobileNavItem>
        </li>
        <li>
          <MobileNavItem
            onClick={() => {
              setMenu();
              handleHoursScroll();
            }}
          >
            HOURS
          </MobileNavItem>
        </li>
        <li>
          <MobileNavItem
            onClick={() => {
              setMenu();
              handleMenuScroll();
            }}
          >
            MENU
          </MobileNavItem>
        </li>
        <li>
          <MobileNavItem
            onClick={() => {
              setMenu();
              handleContactScroll();
            }}
          >
            CONTACT
          </MobileNavItem>
        </li>
      </MobileNavList>
    </MobileNavMenu>
  </Fragment>
);

export default MobileNav;
