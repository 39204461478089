import { withStateHandlers } from "recompose";

export const withNewsletter = withStateHandlers(
  ({ initialState = true }) => ({
    isSignupOpen: initialState
  }),
  {
    closeSignup: ({ isSignupOpen }) => () => ({
      isSignupOpen: false
    })
  }
);
