// @flow

import { compose, withStateHandlers, lifecycle, withHandlers } from "recompose";
import Nav from "./Nav";
import { withScrollHandlers } from '../../../hoc/withScrollHandlers';

const withScrollState = withStateHandlers(
  ({ initialState = "hidden" }) => ({
    logoShown: initialState
  }),
  {
    setLogoShown: () => () => ({
      logoShown: "visible"
    }),
    setLogoHidden: () => () => ({
      logoShown: "hidden"
    })
  }
);

const withScrollListener = withHandlers({
  handleLogo: ({ setLogoShown, setLogoHidden }) => () => {
    if (window.scrollY >= 100) {
      setLogoShown();
    }
    if (window.scrollY < 100) {
      setLogoHidden();
    }
  },
});

const withLifecycle = lifecycle({
  componentDidMount() {
    window.addEventListener("scroll", this.props.handleLogo);
  },
  componentWillUnmount() {
    window.removeEventListener("scroll", this.props.handleLogo);
  }
});

const enhance = compose(
  withScrollState,
  withScrollListener,
  withScrollHandlers,
  withLifecycle
);

export default enhance(Nav);
